import firebase from "firebase/app";
require('firebase/auth')
import "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyAZ869BohHmFBnCMnosNjrON2glTnXmxhs",
  authDomain: "coolfox-b3895.firebaseapp.com",
  databaseURL: "https://coolfox-b3895-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "coolfox-b3895",
  storageBucket: "coolfox-b3895.appspot.com",
  messagingSenderId: "924872662913",
  appId: "1:924872662913:web:b5ea77f6f78ed7ee601280",
  measurementId: "G-GMTG6K0D3J"
};


var firebaseApp = firebase.initializeApp(firebaseConfig);

// Default only exported the database. But when auth needed, it could only fetch the database.
// Fixed by just changing the services and returning the whole firebase object. (could be split into multiple exports, but eh?)
export default firebaseApp;
